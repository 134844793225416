@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Josefin+Slab&family=Josefin+Sans:wght@100;200;300;400;500;600;700;800&family=Roboto:wght@100&display=swap");


body {
  font-family: 'Inter', sans-serif;
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    /*font-family: "Josefin Sans","Helvetica Neue",Helvetica,Arial,sans-serif;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bar-width: 40px;
  --bar-height: 4px;
  --hamburger-gap: 6px;
  --foreground: #ffffff;
  --background: white;
  --hamburger-margin: 8px;
  --animation-timing: 200ms ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
}

.hamburger-menu {
  --x-width: calc(var(--hamburger-height) * 1.41421356237);

  display: flex;
  flex-direction: column;
  gap: var(--hamburger-gap);
  width: max-content;
  /* position: absolute; */
  /* top: var(--hamburger-margin); */
  /* left: var(--hamburger-margin); */
  z-index: 2;
  cursor: pointer;
}

.hamburger-menu:has(input:checked) {
  --foreground: white;
  --background: #ffffff;
}

.hamburger-menu:has(input:focus-visible)::before,
.hamburger-menu:has(input:focus-visible)::after,
.hamburger-menu input:focus-visible {
  border: 1px solid var(--background);
  box-shadow: 0 0 0 1px var(--foreground);
}

.hamburger-menu::before,
.hamburger-menu::after,
.hamburger-menu input {
  content: "";
  width: var(--bar-width);
  height: var(--bar-height);
  background-color: var(--foreground);
  border-radius: 9999px;
  transform-origin: left center;
  transition: opacity var(--animation-timing), width var(--animation-timing),
    rotate var(--animation-timing), translate var(--animation-timing),
    background-color var(--animation-timing);
}

.hamburger-menu input {
  appearance: none;
  padding: 0;
  margin: 0;
  outline: none;
  pointer-events: none;
}

.hamburger-menu:has(input:checked)::before {
  rotate: 45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / -2);
}

.hamburger-menu:has(input:checked)::after {
  rotate: -45deg;
  width: var(--x-width);
  translate: 0 calc(var(--bar-height) / 2);
}

.hamburger-menu input:checked {
  opacity: 0;
  width: 0;
}

.sidebar {
  /* transition: translate var(--animation-timing);
  translate: -100%;
  padding: 0.5rem 1rem;
  padding-top: calc(var(--hamburger-height) + var(--hamburger-margin) + 1rem);
  background-color: var(--foreground);
  color: var(--background);
  max-width: 10rem;
  min-height: 100vh; */
  position: fixed;
  top:48px;
  height: calc(100vh - 48px);
  right:0px;
  width:40%;
  z-index: 999;
  /* background-color: wheat; */
}

.sidebar.collapsed {
  transform: translateX(100%);
}

.react-tabs__tab-list {
  @apply flex flex-wrap pb-2 border-b-2
}

.react-tabs__tab {
  @apply text-eai-dark p-2 mr-2 mb-2
}

.react-tabs__tab:hover {
  @apply text-black border-b-2
}

.react-tabs__tab--selected {
  color: black;
  background-color: #EEEEEE;
  border: 1px solid transparent;
  border-radius: 2px 2px 0 0;
  border-bottom: 2px solid #BFC7B8;
  font-style: normal;
  font-weight: 600;
  color: #333333;
}

.react-tabs__tab:focus{
  border: 1px solid transparent;
}

.react-tabs__tab-panel {
  @apply pt-1
}


.nav-link {

}

.nav-link:active {
  @apply bg-eai-accent-grn
}

.nav-link:hover {
  @apply bg-eai-lt-dark text-white rounded
}


.eai-btn {
  @apply transition border hover:bg-gray-400 bg-white hover:border-gray-400 border-eai-primary-accent hover:text-white text-eai-primary-accent rounded-md px-2 p-2
}

.eai-btn-grn {
  @apply transition border bg-eai-primary-accent hover:bg-gray-400 hover:border-gray-400 border-eai-primary-accent hover:text-white text-white rounded-md p-2
}

.eai-btn-red
{
  @apply transition border bg-white hover:bg-red-600  border-red-600 hover:text-white text-red-600 rounded-md p-2
}

.bg-image {
  background-image: url('/public/Background_1200.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 450px;
  opacity: 0.9;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollable-div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.nav-icon:hover path {
  stroke: #D3EEF2;
}

.nav-icon-selected {
  background-color: #333435;
}

.nav-icon-selected path {
  stroke: #D3EEF2;
}

/* For most browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}